export const all_members = [
  {
    'picture': require("../static/board-members/Lina.jpg"),
    'name': 'Lina Battikha',
    'position': 'President',
    'year': 'Warren College, 4th Year',
    'language': 'Python ',
    'spot': '4th floor DIB',
    'wiclove': 'All the amazing people in WIC 🫶',
    'email': 'lbattikha@ucsd.edu',
    'linkedin': 'https://www.linkedin.com/in/lina-battikha/',
    'instagram': '',
    'github': '',
    'bio': "I'm a San Diego local!"
  },
  {
    'picture': require("../static/board-members/Helena.jpg"),
    'name': 'Helena Hundhausen',
    'position': 'President',
    'year': 'Warren College, 4th Year',
    'language': 'python : ) ',
    'spot': 'bird rock coffee roasters bc the coffee is so so so good',
    'wiclove': 'the empowering and amazing community <3 ',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'i like lifting weights and surfing! '
  },
  {
    'picture': require("../static/board-members/Arushi.jpg"),
    'name': 'Arushi Munjal',
    'position': 'Vice President Internal',
    'year': 'Eleanor Roosevelt College, 3rd Year',
    'language': 'Python',
    'spot': 'Cafe V',
    'wiclove': 'The bond I share with the WIC board is special—more than just working together, we support, celebrate, and uplift each other, making every moment meaningful.',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': ' I mistakenly sat on a seal, thinking it was just a rock :D'
  },
  {
    'picture': require("../static/board-members/Shravi.jpg"),
    'name': 'Shravi Jain',
    'position': 'Vice President External',
    'year': 'Revelle College, 3rd Year',
    'language': 'Java and Python',
    'spot': 'CSE Building',
    'wiclove': 'The supportive community!!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I love reading mystery thrillers. '
  },
  {
    'picture': require("../static/board-members/Advika.jpg"),
    'name': 'Advika Sonti',
    'position': 'Vice President External',
    'year': 'Marshall Collage, 3rd Year',
    'language': 'java',
    'spot': 'Biomed Library',
    'wiclove': 'The supportive and encouraging community of powerful women that WIC has given me :)',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I love to dance!'
  },
  {
    'picture': require("../static/board-members/Zhamilya.jpg"),
    'name': 'Zhamilya Shakirova',
    'position': 'Vice President External',
    'year': 'Warren College, 3rd Year',
    'language': '',
    'spot': '',
    'wiclove': 'People!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I’m international student from Kazakhstan '
  },
  {
    'picture': require("../static/board-members/Jezebel.jpg"),
    'name': 'Jezebel Yangari',
    'position': 'Vice President Finance',
    'year': 'Muir College, 4th Year',
    'language': 'Python',
    'spot': 'old student center',
    'wiclove': 'the community building',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I like to play tennis, read, and listen to music :)'
  },
  {
    'picture': require("../static/board-members/Michelle.jpg"),
    'name': 'Michelle Ma',
    'position': 'Secretary',
    'year': 'Marshall College, 2nd Year',
    'language': 'Java',
    'spot': 'Geisel 8th floor',
    'wiclove': 'Meeting and becoming friends with new people!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I almost always get a sweet treat after my meals :D'
  },
  {
    'picture': require("../static/board-members/Vanessa.jpg"),
    'name': 'Vanessa Hu',
    'position': 'Social Chair',
    'year': 'Sixth College, 4th Year',
    'language': 'Python',
    'spot': 'FAH',
    'wiclove': 'the people and the community!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I love croissants and pastries :)'
  },
  {
    'picture': require("../static/board-members/Kalea.jpg"),
    'name': 'Kalea Porter',
    'position': 'Social Chair',
    'year': 'Seventh College, 3rd Year',
    'language': 'Python ',
    'spot': 'The cute little gardens in Muir <3',
    'wiclove': 'All of our events and the board retreats! ',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I will be an Orientation Leader this year and I work at the Study Abroad Office and University Centers!'
  },
  {
    'picture': require("../static/board-members/Angela.jpg"),
    'name': 'Angela Tsai',
    'position': 'Tech Dev - Events Chair',
    'year': 'Marshall College, 3rd Year',
    'language': 'Python',
    'spot': 'DIB',
    'wiclove': 'Lots of resources!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I make vlog for fun!! :D'
  },
  {
    'picture': require("../static/board-members/Melody.jpg"),
    'name': 'Melody Gill',
    'position': 'Tech Dev - Projects Chair',
    'year': 'Sixth College, 4th Year',
    'language': 'Python',
    'spot': 'RWAC 5th floor',
    'wiclove': 'The supportive community!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I like to read and my favorite genre recently has been sci-fi!'
  },
  {
    'picture': require("../static/board-members/Anshi.jpg"),
    'name': 'Anshi Singh',
    'position': 'Tech Dev - Projects Chair',
    'year': 'Seventh College, 4th Year',
    'language': 'Python/JavaScript',
    'spot': 'CS Basement',
    'wiclove': 'I love the enthusiasm from the members at the events and enjoy the strong sense of community!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I have a twin!'
  },
  {
    'picture': require("../static/board-members/Arpita.jpg"),
    'name': 'Arpita Pandey',
    'position': 'First Year Chair',
    'year': 'Sixth College, 2nd Year',
    'language': 'java',
    'spot': 'muir courtyard',
    'wiclove': 'the amazing women you meet! ',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'i got scouted for shake it up (but never actually went to audition)'
  },
  {
    'picture': require("../static/board-members/Kathy.jpg"),
    'name': 'Kathy Chen',
    'position': "Beginners' Programming Competition Chair",
    'year': 'Sixth College, 4th Year',
    'language': 'Java or C++',
    'spot': 'MOMs cafe or DIB :)',
    'wiclove': 'having a strong supportive community of such brilliant and kind women!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I can’t ride a bike :('
  },
  {
    'picture': require("../static/board-members/Shambhavi.jpg"),
    'name': 'Shambhavi Mittal ',
    'position': "Beginners' Programming Competition Chair",
    'year': 'Seventh College, 3rd Year',
    'language': 'Python',
    'spot': 'My dorm room :) ',
    'wiclove': 'The community! Having a group of encoring women in tech around me is motivating. ',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I do not know how to ride a bicycle! I have tried a few times and have always fell flat on my face. '
  },
  {
    'picture': require("../static/board-members/Trinity.jpg"),
    'name': 'Trinity Ortiz',
    'position': 'DEI Chair',
    'year': 'Warren College, 4th Year',
    'language': 'Python',
    'spot': 'The Vices and Virtues building at night',
    'wiclove': 'Watching people grow in their tech journeys and building their confidence :)',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': "I'm a 2nd degree black belt in taekwondo!"
  },
  {
    'picture': require("../static/board-members/Heidi.jpg"),
    'name': 'Heidi Tam',
    'position': 'PR-Publicity Chair',
    'year': 'Seventh College, 3rd Year',
    'language': 'Python',
    'spot': 'RWAC terrace on the 5th floor',
    'wiclove': 'making new friends while going to events hosted by company partnerships!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I can use chopsticks with my left (non-dominant) hand. '
  },
  {
    'picture': require("../static/board-members/Ginger.jpg"),
    'name': 'Ginger Smith',
    'position': 'PR-Marketing Chair',
    'year': 'Eleanor Roosevelt College, 4th Year',
    'language': 'p5js',
    'spot': 'Terrace on the fifth floor of sixth!',
    'wiclove': 'Social events where we do crafts together :)',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'My favorite snack is movie theater butter popcorn!'
  },
  {
    'picture': require("../static/board-members/Danielle.jpg"),
    'name': 'Danielle Dang',
    'position': 'PR-Marketing Chair',
    'year': 'Sixth College, 3rd Year',
    'language': 'Java ! ',
    'spot': 'Moms cafe ',
    'wiclove': 'The community of WIC! Everyone is super supportive, cool, and nice. ',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'My birthday is on February 29th! I was born in leap year so I’m technically 5! '
  },
  {
    'picture': require("../static/board-members/Pallavi.jpg"),
    'name': 'Pallavi Gaikwad',
    'position': 'Outreach Chair',
    'year': 'Marshall College, 4th Year',
    'language': 'Python',
    'spot': 'Dirty Birds',
    'wiclove': 'The community of empowering women!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I like to ski!'
  },
  {
    'picture': require("../static/board-members/Noah.jpg"),
    'name': 'Noah Kim',
    'position': 'Outreach Chair',
    'year': 'Seventh College, 2nd Year',
    'language': 'Python',
    'spot': 'Roots restaurant',
    'wiclove': "It's inclusivity; how even non-computing majors can receive equal opportunities and resources by being a WIC member ",
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': "I've never had a nosebleed before!"
  },
  {
    'picture': require("../static/board-members/Jenny.jpg"),
    'name': 'Jenny Lam',
    'position': 'Web Development Chair',
    'year': 'Sixth College, 4th Year',
    'language': 'Python',
    'spot': 'DIB',
    'wiclove': 'The friends I made through WIC!',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I learned to write with my left hand when I broke my right arm as a child.'
  },
  {
    'picture': require("../static/board-members/Navyaa.jpg"),
    'name': 'Navyaa Gupta',
    'position': 'VPF Intern',
    'year': 'Sixth College, 2nd Year',
    'language': 'Java',
    'spot': 'Outdoors in Muir',
    'wiclove': 'Meeting wonderful new people at every event',
    'email': '',
    'linkedin': '',
    'instagram': '',
    'github': '',
    'bio': 'I love to bake and try new recipes!'
  },
]